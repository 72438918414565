<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
          type="primary"
          plain
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
        >新增</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="success"
          plain
          icon="el-icon-edit"
          size="mini"
          :disabled="single"
          @click="handleUpdate"
        >修改</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="danger"
          plain
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
        >删除</el-button>
      </el-col>
      <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
    </el-row>

    <el-table v-loading="loading" :data="partnerPosterList" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="主键" align="center" prop="id" />
      <el-table-column label="海报类型" align="center" prop="typeName" />
      <el-table-column label="海报图" align="center" prop="imgs" >
        <template slot-scope="scope">
          　　　　<img :src="scope.row.imgs" height="80" width="60" />
          　　</template>
      </el-table-column>
      <el-table-column label="标题" align="center" prop="title" />
      <el-table-column label="Prompt" align="center" prop="prompt" />
      <el-table-column label="备注" align="center" prop="remark" />
      <el-table-column label="创建时间" align="center" prop="createTime" />
      <el-table-column label="创建者" align="center" prop="createBy" />
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="handleUpdate(scope.row)"
          >修改</el-button>
          <el-button
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="handleDelete(scope.row)"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    
    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />

    <!-- 添加或修改海报管理对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="500px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
<!--        <el-form-item label="海报列表" prop="imgs">-->
<!--          <el-input v-model="form.imgs" placeholder="请输入海报列表" />-->
<!--        </el-form-item>-->

        <el-form-item label="海报" prop="imgs">
          <el-input v-model="form.imgs" placeholder="和本地上传二选一" />
          <template>
            　<img :src="form.imgs" height="60" width="40" />
          </template>
          <el-upload
              :action="uploadThumbImgUrl"
              :limit="1"
              :on-exceed="handleExceed"
              :on-success="handleAvatarSuccessTopic"
              class="upload-demo"
              multiple
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>

        <el-form-item label="类型" :label-width="'120px'" prop="type">
          <el-select v-model="form.type" placeholder="请选择类型">

            <el-option  v-for="item in typeList"
                        :key="item.value"
                        :label="item.name"
                        :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="标题" prop="title">
          <el-input v-model="form.title" placeholder="请输入标题" />
        </el-form-item>
        <el-form-item label="Prompt" prop="prompt">
          <el-input v-model="form.prompt" placeholder="请输入提示词" />
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="form.remark" placeholder="请输入备注" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { listPartnerPoster, getPartnerPoster, delPartnerPoster, addPartnerPoster, updatePartnerPoster, exportPartnerPoster } from "@/api/thirdPart/partnerPoster";
import { listType } from "@/api/work/type";
export default {
  name: "PartnerPoster",
  components: {
  },
  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 海报管理表格数据
      partnerPosterList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 主键字典
      idOptions: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        id: null,
        type: null,
        title: null,
        writingTime: null,
      },
      // 表单参数
      form: {},
      //类型列表
      typeList:[],
      // 表单校验
      rules: {
      },
    //  uploadImgUrl: "https://api.sky1998.cn" + "/common/file/uploadToTencentOss", // 上传的图片服务器地址
      uploadThumbImgUrl: "https://api.sky1998.cn" + "/common/file/uploadToTencentOss_thumb", // 上传的图片服务器地址
    };
  },
  created() {
    this.getList();
    this.getDicts("${column.dictType}").then(response => {
      this.idOptions = response.data;
    });

    //获取类型列表
    listType({wxappType: 4}).then(response => {
      this.typeList = response.datas;

    });
  },
  methods: {
    /** 查询海报管理列表 */
    getList() {
      this.loading = true;
      listPartnerPoster(this.queryParams).then(response => {
        this.partnerPosterList = response.datas;
        this.total = response.total;
        this.loading = false;
      });
    },
    // 主键字典翻译
    idFormat(row, column) {
      return this.selectDictLabel(this.idOptions, row.id);
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: null,
        imgs: null,
        title: null,
        prompt: null,
        remark: null,
        createTime: null,
        createBy: null
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length!==1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加海报管理";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      getPartnerPoster(id).then(response => {
        this.form = response.datas;
        this.open = true;
        this.title = "修改海报管理";
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.id != null) {
            updatePartnerPoster(this.form).then(response => {
              this.$modal.msgSuccess("修改成功");
              this.open = false;
              this.getList();
            });
          } else {
            addPartnerPoster(this.form).then(response => {
              this.$modal.msgSuccess("新增成功");
              this.open = false;
              this.getList();
            });
          }
        }
      });
    },
    //上传图片成功后
    handleAvatarSuccessTopic(res, file) {
   //   this.form.imgs=res.datas
      this.form.imgs=res.datas.nativeUrl
      this.form.thumb=res.datas.thumbUrl
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      this.$confirm('是否确认删除海报管理编号为"' + ids + '"的数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return delPartnerPoster(ids);
        }).then(() => {
          this.getList();
          this.$modal.msgSuccess("删除成功");
        })
    },
    /** 导出按钮操作 */
    handleExport() {
      const queryParams = this.queryParams;
      this.$confirm('是否确认导出所有海报管理数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return exportPartnerPoster(queryParams);
        }).then(response => {
          this.download(response.msg);
        })
    }
  }
};
</script>
